// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/feedback_icons/feedback_sent_white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/info_icon_thin.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.finished-assignment[data-v-6fd4add8] {\n  display: block;\n  width: 100%;\n  padding: 40px 10px 30px 10px;\n  text-align: center;\n}\n.finished-assignment__text[data-v-6fd4add8] {\n  margin-top: 10px;\n  font-size: 22px;\n}\n.finished-assignment__description[data-v-6fd4add8] {\n  margin-top: 5px;\n  font-weight: bold;\n  font-size: 14px;\n}\n.finished-assignment__icon[data-v-6fd4add8] {\n  width: 100px;\n  height: 100px;\n  margin: 10px auto;\n  background-size: 100%;\n}\n\n/***** Success Type  ****/\n.finished-assignment.success[data-v-6fd4add8] {\n  background-color: #008489;\n}\n.finished-assignment.success .finished-assignment__text[data-v-6fd4add8] {\n  color: #fff;\n}\n.finished-assignment.success .finished-assignment__description[data-v-6fd4add8] {\n  color: #fff;\n}\n\n/***** Information Type  ****/\n.finished-assignment.information[data-v-6fd4add8] {\n  background-color: var(--color-warning-200, #f0e8dd);\n}\n.finished-assignment.info .finished-assignment__text[data-v-6fd4add8] {\n  color: #000;\n}\n.finished-assignment.info .finished-assignment__description[data-v-6fd4add8] {\n  color: #000;\n}\n\n/***** Icons  ****/\n.finished-assignment__icon.tick[data-v-6fd4add8] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.finished-assignment__icon.info[data-v-6fd4add8] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.finished-assignment__description[data-v-6fd4add8]:not(:last-child) {\n  margin-bottom: 15px;\n}\n@media (max-width: 767px) {\n.finished-assignment[data-v-6fd4add8] {\n    padding-top: 100px;\n    padding-bottom: 150px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
